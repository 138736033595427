import { ApplicationConfig, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { APP_CONFIG } from './services/config/config.constants';
import { appThemeConfig } from './core/app.config';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { environment } from 'environments/environment';
import { AuthenticationEffect } from './store/effects/authentication.effect';
import { provideAnimations } from '@angular/platform-browser/animations';
import { translocoCustomProvider } from './core/transloco/transloco.provider';
import { reducers } from './store/reducers';
import { provideHttpInterceptor } from './core/interceptors/http.provider';
import { RegisterEffect } from './store/effects/register.effects';
import { Constants } from './core/data/constants';
import { SearchEffects } from './store/effects/search.effects';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import {provideNgcCookieConsent} from 'ngx-cookieconsent';
import { cookieConfig }  from './core/ngx-cookie-config/cookei-config'

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes),
    provideHttpInterceptor(),
    provideHttpClient(
      withFetch(),
    ),
    translocoCustomProvider(),
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: true,
        includeHeaders:[
          //  TODO :  /* Specifies which headers should be included into cached responses.*/ contact ahmed for more infos
          Constants.RESPONSE_HEADER_TOTAL_ITEMS,
        ]
      }),
    ),
    provideStore(reducers),
    provideEffects(
      AuthenticationEffect,RegisterEffect, SearchEffects
    ),
    provideStoreDevtools(
        ({ maxAge: 25, logOnly: environment.production })
    ),
    provideZoneChangeDetection(),
    {
      provide: APP_CONFIG,
      useValue: appThemeConfig
    },
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    provideNgcCookieConsent(cookieConfig)
  ]
};
