import { APP_INITIALIZER, EnvironmentProviders, importProvidersFrom, inject, Provider } from '@angular/core';
import { provideTransloco, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { TranslocoHttpLoader } from './transloco.http-loader';
import { environment } from 'environments/environment';
// import { provideTranslocoPreloadLangs } from '@ngneat/transloco-preload-langs/lib/preload-langs.providers';

export const translocoCustomProvider = (): Array<Provider | EnvironmentProviders> => {
  return [
    importProvidersFrom(TranslocoModule),
    provideTransloco({
      config: {
        availableLangs: [
          {
            id: 'en',
            label: 'English'
          },
          {
            id: 'fr',
            label: 'Français'
          },
          {
            id: 'es',
            label: 'Espanol'
          },
        ],
        defaultLang: 'fr',
        fallbackLang: 'fr',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader
    }),
    {
      // Provide the default Transloco loader
      provide: TRANSLOCO_LOADER,
      useClass: TranslocoHttpLoader,
    },
    {
      // Preload the default language before the app starts to prevent empty/jumping content
      provide: APP_INITIALIZER,
      useFactory: () => {
        const translocoService = inject(TranslocoService);
        const defaultLang = translocoService.getDefaultLang();
        translocoService.setActiveLang(defaultLang);
        return () => translocoService.getDefaultLang();
      },
      multi: true,
    },
    // provideTranslocoPreloadLangs(['fr','en',]),

  ];
};
