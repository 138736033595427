import { NgcCookieConsentConfig } from "ngx-cookieconsent";
import { environment } from "environments/environment";
export const cookieConfig:NgcCookieConsentConfig = {
    cookie: {
      domain:environment.env === 'prod' ? 'spartanjob.ch' : (environment.env === 'dev' ? 'apply-dev.spartanjob.ch' : 'localhost') // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    },
    position: 'bottom',
    palette: {
      popup: {
        background: 'rgb(51 93 153)',
        text: "#fff"
  
      },
      button: {
        background: '#234a81',
        text: '#fff'
      }
    },
    content: {
      link: ''
    },
    theme: 'edgeless',
    type: 'info'
  };