import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private _httpClient: HttpClient) { }

  /**
   * Get translation
   *
   * @param lang
   */
  getTranslation(lang: string): Observable<Translation> {
    const translationVersion = '0.1.31';
    const translateUrl = `${environment.ENDPOINTS.BASE_URL}/assets/i18n/${lang}.json?v=${translationVersion}`;
    return this._httpClient.get<Translation>(translateUrl); /* for ssr */
  }
}
