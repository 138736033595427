import { PLATFORM_ID, inject } from "@angular/core";
import { Router } from "@angular/router";
import { map } from 'rxjs';
import { AuthService } from "./service/auth.service";
import { Constants } from "../data/constants";
import { isPlatformBrowser } from "@angular/common";

export const authGuard = () => {

  const authService = inject(AuthService);
  const router = inject(Router);

  const _platformId = inject(PLATFORM_ID)

  return authService.checkIsLoggedIn().pipe(
    map((isLogged: boolean | null) => /*isPlatformBrowser(_platformId) ?*/ isLogged
      ? true
      : router.createUrlTree([Constants.LOGIN_ROUTE])
      /*: false */
    )
  );



}
