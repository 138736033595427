import { isPlatformBrowser } from "@angular/common";
import { PLATFORM_ID, inject } from "@angular/core";
import { Router } from "@angular/router";
import { CookiesUtilsService } from "app/services/cookies-utils/cookies-utils.service";
import { Constants } from "../data/constants";



export const isFirstLoginGuard = () => {

  const cookiesUtilsService = inject(CookiesUtilsService);
  const token = cookiesUtilsService.getAccessToken()
  const _platformId = inject(PLATFORM_ID)
  const router = inject(Router)

    // TODO : token decoded then verify if first login or not
  return isPlatformBrowser(_platformId) ?
     (token && token !== '' && token !== 'undefined' && token !== 'null' && cookiesUtilsService?.getDecodedAccessToken()?.firstLogin === true)
            ? true
            : router.createUrlTree([Constants.HOME_ROUTE])
      : false;

}
