import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { PLATFORM_ID, inject } from '@angular/core';
import { CommonService } from 'app/services/common/common.service';
import { CookiesUtilsService } from 'app/services/cookies-utils/cookies-utils.service';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { AuthService } from '../auth/service/auth.service';
import { Store } from '@ngrx/store';
import { logout } from 'app/store/actions/authentication.action';
import { Constants } from '../data/constants';


/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const httpInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const _cookiesUtilsService = inject(CookiesUtilsService);
  const _commonService = inject(CommonService);
  const _platformId = inject(PLATFORM_ID);
  const _store = inject(Store);
  let modified = request;

  if (request.headers.has(Constants.AVOID_INTERCEPTOR_HEADER)) {
    // Pass the request through without intercepting it
    return next(request);
  }

  if (_cookiesUtilsService.getAccessToken()) {
    modified = request.clone({ setHeaders: {
      Authorization: 'Bearer ' + _cookiesUtilsService.getAccessToken()
    } });
  }



  return next(modified).pipe(
    retry(0),
    catchError((error: HttpErrorResponse) => {
      const isErrorEventType = isPlatformBrowser(_platformId) ? error.error instanceof ErrorEvent : false;
      if (!(isErrorEventType)) {
        switch (error.status) {
          case 400:{
            if (error.error && error.error.errorKey) {
              showErrorSnackbar({ errorMessage: 'errors.' + error.error.errorKey, commonService: _commonService })
            } else {
              showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage400', commonService: _commonService })
            }
            break;
          }

          case 401:
            if (error.error && error.error.errorKey ) {
              showErrorSnackbar({ errorMessage: 'errors.' + error?.error?.errorKey, commonService: _commonService })
            } else {
              showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage401' , commonService: _commonService })
              _store.dispatch(logout({redirect:true}));
            }

            break;
          case 403:
            if (error.error && error.error.errorKey) {
              showErrorSnackbar({ errorMessage: 'errors.' + error.error.errorKey, commonService: _commonService })
            } else {
              showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage403', commonService: _commonService })
            }
            break;
          case 404:
            if (error.error && error.error.errorKey) {
              showErrorSnackbar({ errorMessage: 'errors.' + error.error.errorKey, commonService: _commonService })
            } else {
              showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage404', commonService: _commonService })
            }
            break;

          case 405:
            if (error.error && error.error.errorKey) {
              showErrorSnackbar({ errorMessage: 'errors.' + error.error.errorKey, commonService: _commonService })
            } else {
              showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage405', commonService: _commonService })
            }
            break;

          case 500:
            showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage500', commonService: _commonService })
            break;
          case 502:
            showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage502', commonService: _commonService })
            break;
          case 504:
            if (error.error && error.error.errorKey) {
              showErrorSnackbar({ errorMessage: 'errors.' + error.error.errorKey, commonService: _commonService })
            } else {
              showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage504', commonService: _commonService })
            }
            break;
          default:
            showErrorSnackbar({ errorMessage: 'errors.unknown_error', commonService: _commonService })
            break;
        }
      }
      return throwError(error);
    })
  );
}

const showErrorSnackbar = ({ errorMessage, commonService }: { errorMessage: string, commonService?: CommonService }) => {
  commonService?.showSnackBar({ message: errorMessage, snackBarType: commonService?.errorMessage, });
}


