import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { Actions,  } from "@ngrx/effects";



@Injectable()
export class SearchEffects {

    private actions$ = inject(Actions);
    private router = inject(Router);

}
