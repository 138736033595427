import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Layout } from './layout.types';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';
import { AppConfig } from '../core/app.config';
import { SharedModule } from '../shared/shared.module';
import { TranslocoModule } from '@ngneat/transloco';

import { AppLayoutComponent } from './app-layout/app-layout.component';
import { EmptyLayoutComponent } from './empty-layout/empty-layout.component';
import { ConfigService } from 'app/services/config';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    SharedModule,
    TranslocoModule,
    AppLayoutComponent,
    EmptyLayoutComponent
  ]
})
export class LayoutComponent implements OnInit, OnDestroy {

  layout?: Layout;
  config?: AppConfig;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _configService: ConfigService,
    @Inject(DOCUMENT) private _document: any,

  ) { }

  ngOnInit(): void {

    // Subscribe to config changes
    this._configService.config$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: AppConfig) => {

        // Store the config
        this.config = config;

        // Update the layouti
        this._updateLayout();

        // update scheme
        this.updatescheme();

      });

    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this._unsubscribeAll)
    ).subscribe(() => {

      // update the layout
      this._updateLayout();
    })
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  _updateLayout(): void {
    const layoutFromQueryParam = (this._activatedRoute.snapshot.queryParamMap.get('layout') as Layout);

    if (layoutFromQueryParam) {
      this.layout = layoutFromQueryParam;
    }

    const paths = this._activatedRoute.pathFromRoot;
    paths.forEach((path) => {
      // Check if there is a 'layout' data
      if (path.routeConfig && path.routeConfig.data && path.routeConfig.data['layout']) {
        // Set the layout
        this.layout = path.routeConfig.data['layout'] ?? 'empty-layout';
      }
    });
  }

  // update theme scheme
  updatescheme(): void {
    // Remove class names for all schemes
    this._document.body.classList.remove('light', 'dark');

    // Add class name for the currently selected scheme
    this._document.body.classList.add('light');
  }

  private _updateTheme(): void
  {
      // Find the class name for the previously selected theme and remove it
      // this._document.body.classList.forEach((className: string) =>
      // {
      //     if ( className.startsWith('theme-') )
      //     {
      //         this._document.body.classList.remove(className, className.split('-')[1]);
      //     }
      // });

      // Add class name for the currently selected theme
      this._document.body.classList.add('theme-default');
  }

}
