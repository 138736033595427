import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { CandidateModel } from "app/core/models/candidate.model";
import { CommonService } from "app/services/common/common.service";
import { DataLogicService } from "app/services/data-logic/data-logic.service";
import { environment } from "environments/environment";
import { catchError,  delay, finalize, map, mergeMap, of, tap } from "rxjs";
import { registerFailure, registerLoading, userInformationsAction } from "../actions/register.actions";
import { loginSuccess, setIsFirstLogin } from "../actions/authentication.action";
import { StorageUtilsService } from "app/services/storage-utils/storage-utils.service";
import { CookiesUtilsService } from "app/services/cookies-utils/cookies-utils.service";
import { AuthenticationResponse } from "app/core/models/authentication-response";


@Injectable()
export class RegisterEffect {
  private _cookiesUtilsService = inject(CookiesUtilsService)

  actions$ = inject(Actions);
  _router = inject(Router)
  private _store = inject(Store);
  _commonService = inject(CommonService)
  _candidateInfosService = inject(DataLogicService<CandidateModel | any>)
  validateUserInfos$ = createEffect(() => this.actions$.pipe(
    ofType(userInformationsAction),
    mergeMap(
      ({ userInformations }) => {
        /** form data values  */
        const formData = this.setFormDataValues({userInformations})
        return this._candidateInfosService.postData({
          url: environment.ENDPOINTS.CANDIDATE_CREATE_URL,
          dataToPost: formData,
        })
          .pipe(
            tap((response:AuthenticationResponse) => {
              this._cookiesUtilsService.setAccessToken({token:response.id_token});
            }),
            map((response) =>{
              this._store.dispatch(setIsFirstLogin({isFirstLogin:false}))
              return loginSuccess({ accessToken: response.id_token, })
            }

            ),
            catchError((error) =>
              of(registerFailure({ registerError: error }))
            ),
            finalize(() =>
              this._store.dispatch(registerLoading({ isLoading: false }))
            )
          )
      }

    )
  ))

  /** set form data values */
  setFormDataValues({ userInformations, }: {   userInformations: {
    candidate: CandidateModel | null,
    candidateImage: any,
    candidateResume:any,
  }, }): FormData {
    const formData = new FormData()

    /** candidate dto */
    if (!formData.has('candidateDTO')) {
      formData.append('candidateDTO', new File([JSON.stringify({...userInformations.candidate})], 'candidateDTO.json', { type: 'application/json' }));
    }
    else {
      formData.set('candidateDTO', new File([JSON.stringify({...userInformations.candidate, candidateImage:null})], 'candidateDTO.json', { type: 'application/json' }))
    }

    /** candidate image */
    if(userInformations?.candidateImage){
        const blob = new Blob([userInformations.candidateImage], { type: 'image/jpeg'});
        if (formData.has('candidatePhoto')) {
            formData.append('candidatePhoto', blob,'image.jpg');
        }
        else {
            formData.set('candidatePhoto', blob,'image.jpg');
        }
    }
    /** candidate resume */
    if(userInformations?.candidateResume){
      const blob = new Blob([userInformations?.candidateResume], { type: userInformations?.candidateResume?.type});
      if (formData.has('candidateResume')) {
          formData.append('candidateResume', blob,userInformations?.candidateResume?.name);
      }
      else {
          formData.set('candidateResume', blob,userInformations?.candidateResume?.name);
      }
    }

    return formData;
  }

}



