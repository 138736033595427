import { Component, OnInit, inject } from '@angular/core';

import {  RouterOutlet } from '@angular/router';
import { TranslocoModule,  } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { loginSuccess, setIsFirstLogin } from './store/actions/authentication.action';
import { CookiesUtilsService } from './services/cookies-utils/cookies-utils.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [TranslocoModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers:[]
})
export class AppComponent implements OnInit {

  private _cookiesUtilsService : CookiesUtilsService = inject(CookiesUtilsService)
  private _store  = inject(Store)

  /** title for unit testing - just a try */
  title = 'spartanjob-candidate'

  ngOnInit(): void {
    const accessToken = this._cookiesUtilsService.getAccessToken();

    if(accessToken && (accessToken !== 'undefined' && accessToken !== '')){
        const accessTokenDecoded = this._cookiesUtilsService.getDecodedAccessToken();
        this._store.dispatch(setIsFirstLogin({isFirstLogin:accessTokenDecoded.firstLogin}))
        this._store.dispatch(loginSuccess({accessToken:accessToken,}));
    }
  }
}
